* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Karla", sans-serif;
}

html {
  scroll-padding-top: 85px; /* height of sticky header */
}

body {
  background: white;
}

@media screen and (max-width: 960px) {
  nav {
    transition: 0.8s all ease;
  }
}

@media screen and (max-width: 900px) {
  #nav-mobile-icon {
    display: block !important;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #1f1a17;
  }

  #nav-menu {
    display: none !important;
  }

  #nav-contact-btn {
    display: none !important;
  }
}

nav {
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
}

#nav-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  background-color: white;
}

#nav-logo {
  height: 60%;
  width: auto;
  align-self: center;
}

#nav-mobile-icon {
  display: none;
}

#nav-right-side {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
}

.nav-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.nav-item a {
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  color: #1f1a17;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

.nav-item a:hover {
  color: #0088a9;
}

#nav-contact-btn {
  margin-left: 30px;
  padding: 9px 25px;
  background-color: rgba(31, 26, 23, 1);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: white;
}

#nav-contact-btn:hover {
  background-color: rgba(31, 26, 23, 0.8);
}

.project-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.project-overlay-text {
  background-color: transparent;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 90%;
  left: 5%;
  text-align: center;
}

.project-item:hover .project-overlay {
  opacity: 1;
}

.project-image {
  display: block;
  width: 100%;
  height: auto;
}

#submit-contact-btn {
  padding: 9px 25px;
  background-color: rgba(31, 26, 23, 1);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: white;
}

#submit-contact-btn:hover {
  background-color: rgba(31, 26, 23, 0.8);
}
