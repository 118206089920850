@media screen and (max-width: 480px) {
  #sidebar-menu {
    grid-template-rows: (4, 100px);
  }
}

#sidebar-container {
  position: fixed;
  z-index: 900;
  width: 100%;
  height: 100%;
  background-color: #f2f4f6;
  display: grid;
  align-items: center;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: 0;
  top: -100%;
}

#sidebar-close-icon-container {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}

#sidebar-menu {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 100px);
  text-align: center;
  background-color: #f2f4f6;
  padding-left: 0;
}

.sidebar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  background-color: #f2f4f6;
}

.sidebar-item a {
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  color: #1f1a17;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.sidebar-item a:hover {
  color: #0088a9;
}

#sidebar-btn-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
}

#sidebar-contact-btn {
  padding: 9px 25px;
  background-color: rgba(31, 26, 23, 1);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  color: white;
}

#sidebar-contact-btn:hover {
  background-color: rgba(31, 26, 23, 0.8);
}
